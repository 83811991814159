(function(i, p, w, d, b, $, t){
    var TNT_Parallax_Ad = {
        'el': {
            'ad': '#x-parallax',
            'ad_clip': '#x-parallax-clip',
            'ad_container': '#x-parallax-container',
            'ad_creative': '#x-parallax-ad'
        },
        'ad_html': function() {
            var html = ''+
            '<style>'+
                this.el.ad+' {overflow: hidden!important; position: relative!important; box-sizing: border-box!important;}'+
                this.el.ad_clip+' {position: absolute!important; top: 0!important; left: 0!important; width: 100%!important; height: 100%!important; border: 0!important; margin: 0!important; padding: 0!important; clip: rect(0,auto,auto,0)!important; -webkit-clip-path: polygon(0px 0px,100% 0px,100% 100%,0px 100%)!important; clip-path: polygon(0px 0px,100% 0px,100% 100%,0px 100%)!important;}'+
                this.el.ad_container+' {position: fixed!important; top: 0!important; width: 100%; height: 100%; -webkit-transform: translateZ(0)!important; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}'+
                this.el.ad_creative+' {overflow: hidden!important; display: inline-block; position: relative;}'+
            '</style>'+
            '<div id="'+this.el.ad.replace('#','')+'" style="height:'+this.settings.window_height+'px; background:#'+this.settings.window_background_color+';">'+
                '<div id="'+this.el.ad_clip.replace('#','')+'">'+
                    '<div id="'+this.el.ad_container.replace('#','')+'">'+
                        '<div id="'+this.el.ad_creative.replace('#','')+'" style="width:'+this.settings.image_width+'px; height:'+this.settings.image_height+'px;">'+
                            '<a href="'+this.settings.click_tag+'" target="_blank" rel="noopener">'+
                                '<img src="'+this.settings.image+'" width="'+this.settings.image_width+'" height="'+this.settings.image_height+'" border="0" />'+
                            '</a>'+
                        '</div>'+
                    '</div>'+
                '</div>';
                if (this.settings.tracking_pixel) html += '<div style="display:none;"><img src="'+this.setting.tracking_pixel+'" height="1" width="1" /></div>';
            html += '</div>';
            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            var that = this;

            $(d).find(p).hide();
            $(d).find(p).parent().prepend(this.ad_html());

            var $insertedAd = $(d).find(p).parent().find(this.el.ad);
            $insertedAd.find(this.el.ad_container).width($insertedAd.find(this.el.ad_clip).width());

            $(w).on('resize', function() {
                $insertedAd.find(that.el.ad_container).width($insertedAd.find(that.el.ad_clip).width());
            });
        },
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        $(t.cmds).each(function() {
            TNT_Parallax_Ad.load(this.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.__TNT_AD || {})
);